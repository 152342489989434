import { useMiniAppContext } from "../components/context";

const useHideTutorial = () => {
  const { setState } = useMiniAppContext();

  const fetcher = async (url: string) => {
    const refId = localStorage.getItem("refId");

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ playerId: refId }),
    });

    const data = await response.json();

    setState((prevState) => ({
      ...prevState,
      isTutorialVisible: false,
    }));

    return data;
  };

  const mutate = async () => {
    try {
      const data = await fetcher(
        `${process.env.REACT_APP_API_URL}/Player/disable-tutorial`
      );
      return data;
    } catch (error) {
      console.error("Error disabling tutorial:", error);
      throw error; // Rethrow to handle it where mutate is called if needed
    }
  };

  return { mutate };
};

export default useHideTutorial;
