import useSWR from "swr";
import { IGames } from "../interfaces/interfaces";
import { swrSingleGetConfig } from "../configs/swrConfig";

const useGetGames = () => {
  const fetcher = async (url: string) => {
    const playerId = localStorage.getItem("refId");

    const response = await fetch(`${url}?PlayerId=${playerId}`, {
      method: "GET",
    });

    return await response.json();
  };

  const { data, mutate, isLoading } = useSWR<IGames>(
    `${process.env.REACT_APP_API_URL}/Game`,
    fetcher,
    swrSingleGetConfig
  );

  return { data, isLoading, mutate };
};

export default useGetGames;
