import { PropsWithChildren } from "react";
import Badge from "../../badge";
import { IReferral } from "../../../interfaces/interfaces";
import { getAvatarPicture } from "../../../utils/getImage";
import moment from "moment";
import { RefStyledCard } from "./style";

interface ICardProps {
  data: IReferral;
}

const CardRefferal: React.FC<PropsWithChildren & ICardProps> = (props) => {
  const { childReferral, created } = props.data;
  const { userName, coins, level } = childReferral;
  return (
    <RefStyledCard>
      <div
        style={{
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "1rem",
          textAlign: "left",
          padding: ".25rem .75rem",
          background: "#FFFFFF0D",
          color: "#646970",
          width: "fit-content",
          display: "flex",
          backdropFilter: "blur(10px)",
          borderBottomRightRadius: ".75rem",
        }}
      >
        Invited {moment(created).format("D MMM, YY")}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: " 0.875rem",
          gap: "0.875rem",
        }}
      >
        {/* TODO: Randomize  */}
        <img
          src={getAvatarPicture("user")}
          style={{
            borderRadius: ".5rem",
            height: "3.625rem",
            width: "3.625rem",
          }}
          alt="avavar"
        />
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.375rem" }}
        >
          <p
            style={{
              margin: 0,
              fontSize: "1.125rem",
              lineHeight: "1.5rem",
              fontWeight: 900,
            }}
          >
            {userName.toUpperCase()}
          </p>
          <div
            style={{
              display: "flex",
              gap: "0.375rem",
            }}
          >
            <Badge coins={coins} />
            <Badge level={level} type="exp" />
          </div>
        </div>
      </div>
    </RefStyledCard>
  );
};

export default CardRefferal;
