import { useState } from "react";
import { PageWrapper } from "../../style";
import { PulsatingLabel, SpinningWheel, StopperIcon, WinLabel } from "./style";
import { ReactComponent as AvatarIcon } from "./icons/avatar-coin-icon.svg";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useSpinWheel } from "../../../hooks/useSpinWheel";
import { useMiniAppContext } from "../../../components/context";

const WheelPage: React.FC = () => {
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [impactOccurred] = useHapticFeedback();
  const { setState } = useMiniAppContext();

  const { data, trigger } = useSpinWheel();
  return (
    <PageWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "100%",
          overflow: "hidden",
          padding: "0 1rem",
          background: "linear-gradient(to top, #9747FF, transparent)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.75rem",
            }}
          >
            <AvatarIcon style={{ marginRight: ".5rem" }} />
            {data ? (
              <WinLabel
                $enabled={enabled}
                style={{ color: data.color }}
                onAnimationEnd={() => setEnabled(false)}
              >
                <p
                  style={{
                    fontSize: "2rem",
                    lineHeight: "3rem",
                    fontWeight: 800,
                    width: "6rem",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    +
                  </span>
                  {data.win}
                </p>
              </WinLabel>
            ) : (
              <PulsatingLabel>
                <p
                  style={{
                    fontSize: "2rem",
                    lineHeight: "3rem",
                    fontWeight: 900,
                  }}
                >
                  Tap to win!
                </p>
              </PulsatingLabel>
            )}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            height: "20rem",
          }}
        >
          <StopperIcon />
          <SpinningWheel
            onAnimationEnd={() => {
              setIsSpinning(false);
            }}
            $isSpinning={isSpinning}
            $isDisabled={isDisabled}
            onClick={async () => {
              if (!isSpinning && !isDisabled) {
                setIsDisabled(true);
                setIsSpinning(true);
                impactOccurred("medium");
                await trigger().then((response) => {
                  setState((prevState) => ({
                    ...prevState,
                    coins: response.player.coins,
                    perHour: response.player.incomePerHour,
                    level: response.player.level,
                  }));
                  setIsDisabled(false);
                });
              }
            }}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default WheelPage;
