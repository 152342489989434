import styled, { css, keyframes } from "styled-components";
import { ReactComponent as Wheel } from "./icons/fortune-wheel-icon.svg";
import { ReactComponent as Stopper } from "./icons/stopper-icon.svg";

export const WheelWrapper = styled.div({
  margin: "0 auto",
});

const spinAnimation = keyframes`
  0% {
    transform: translate(-50%, 50%) scale(.8) rotate(0deg);
  }
  100% {
    transform: translate(-50%, 50%) scale(1) rotate(720deg);
  }
`;

const winAnimation = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0;
  } 
  50% {
    transform: scale(1.2) ;
    opacity: 0.5;
  }
  100% {
    transform: scale(1) ;
    opacity: 1;
  }
`;

const labelAnimation = keyframes`
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
`;

// Define the props type for the spinning wheel
type SpinningWheelProps = {
  $isSpinning: boolean;
  $isDisabled: boolean;
};
type WinLabelProps = {
  $enabled: boolean;
};

// Styled component for the spinning wheel\
// animation cheatsheet:
// 2s: animation-duration
// ease-in-out: animation-timing-function
// 0s: animation-delay
// 1: animation-iteration-count
// normal: animation-direction
// forwards: animation-fill-mode
export const SpinningWheel = styled(Wheel)<SpinningWheelProps>`
  ${(props) =>
    props.$isSpinning &&
    css`
      animation: ${spinAnimation} 0.3s ease-in-out 0s 1 normal forwards;
    `}
  ${(props) =>
    props.$isDisabled &&
    css`
      pointer-events: none;
    `}
  position: absolute;
  z-index: 5;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

//  style={{ position: "absolute", zIndex: 6, marginBottom: "1.5rem" }}
export const StopperIcon = styled(Stopper)({
  position: "absolute",
  bottom: "80%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 6,
});

export const PulsatingLabel = styled.div`
  ${() =>
    css`
      animation: ${labelAnimation} infinite ease-in-out 0.8s alternate-reverse
        both;
    `}
`;

export const WinLabel = styled.div<WinLabelProps>`
  ${(props) =>
    props.$enabled &&
    css`
      animation: ${winAnimation} 0.3s linear 0.2s 1 normal forwards;
    `},
`;
