import { getWebpImageUrl } from "../../../utils/getImage";
import { DynamicStyledCard } from "./style";
import { memo, PropsWithChildren, useState } from "react";
import Badge from "../../badge";
import { formatMinutes } from "../../../utils/formats";
import { IPlayerGame } from "../../../interfaces/interfaces";
import CountDown from "../../countDown";
import _ from "lodash";
import WebPImage from "../../webpImage";
import moment from "moment";
import { useClaimPlayerGame } from "../../../hooks/useClaimPlayerGame";
import usePlayer from "../../../hooks/usePlayer";

interface ICardProps {
  data: IPlayerGame;
  // onClaim: () => void;
}

const CardGame: React.FC<PropsWithChildren & ICardProps> = memo((props) => {
  const { game, actualDateTime, nextClaim, incomePerHour, count, id } =
    props.data;
  const { duration, name } = game;
  const { trigger } = useClaimPlayerGame();
  const { mutate } = usePlayer();

  const [actualDate, setActualDate] = useState(moment.utc(actualDateTime));
  const [nextClaimDate, setNextClaimDate] = useState(moment.utc(nextClaim));

  const onClaim = () =>
    trigger({ id }).then(() => {
      mutate();
    });

  // Get the difference in seconds
  const difference = nextClaimDate.diff(actualDate, "milliseconds");
  // TODO: replace inline style with styled-components
  return (
    <DynamicStyledCard $isClaimable={difference < 0}>
      {/* // image background */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.875rem",
          width: "100%",
        }}
      >
        <div
          style={{
            borderRadius: "0.875rem",
            background: "#242427",
            height: "9rem",
            width: "7.625rem",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              fontSize: "0.625rem",
              fontWeight: 500,
              letterSpacing: "0.0717rem",
              lineHeight: "1rem",
              textAlign: "left",
              padding: ".25rem .75rem",
              background: "rgba(255, 255, 255, 0.05)",
              backdropFilter: "blur(10px)",
              borderBottomRightRadius: ".75rem",
              display: "inline-block",
              verticalAlign: "top",
              zIndex: 10,
              position: "absolute",
            }}
          >
            {formatMinutes(+duration)}
          </div>
          <WebPImage
            style={{
              height: "9rem",
              width: "7.625rem",
              zIndex: 9,
              position: "absolute",
              transform: "translate(10%, 10%)",
              scale: "1.25",
            }}
            src={getWebpImageUrl(name)}
            alt={`game/${name}`}
          />
        </div>
        <div style={{ display: "inline-block", flex: 1 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "1.5rem",
                lineHeight: "1.375rem",
                fontWeight: 600,
                letterSpacing: "0.0116rem",
              }}
            >
              {_.capitalize(name)}
            </p>
            <div
              style={
                difference < 0
                  ? {
                      width: "1rem",
                      height: "1rem",
                      borderRadius: "50%",
                      background: "#9747FF",
                    }
                  : {}
              }
            ></div>
          </div>

          <div
            style={{
              margin: "1rem 0",
              display: "flex",
              gap: "0.375rem",
            }}
          >
            <Badge label="Level" level={count} type="exp" />
            <Badge label="Per hour" coins={incomePerHour} />
          </div>
          <CountDown onClaim={() => onClaim()} difference={difference} />
        </div>
      </div>
    </DynamicStyledCard>
  );
});

export default CardGame;
