import { FC, ReactNode, useEffect } from "react";
import { StyledLayout } from "./style";
import usePlayer from "../hooks/usePlayer";
import { useMiniAppContext } from "../components/context";
import { useNavigate } from "react-router";

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const { state } = useMiniAppContext();
  const navigate = useNavigate();

  usePlayer();
  useEffect(() => {
    if (state.isTutorialVisible) {
      // Navigate to tutorial page when tutorial is active.
      navigate("/auth/tutorial");
    }
  }, [state.isTutorialVisible]);

  return <StyledLayout>{children}</StyledLayout>;
};
