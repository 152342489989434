import styled from "styled-components";

export const StyledCard = styled.div({
  background: "#FFFFFF1F",
  color: "#FFFFFF",
  display: "block",
  padding: " 0.875rem",
  borderRadius: "1rem",
  width: "inherit",
  backdropFilter: "blur(10px)",
  WebkitBackdropFilter: "blur(10px)" /* For Safari */,
});
