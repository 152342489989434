import { FC, useState } from "react";
import {
  Card,
  H2,
  Text,
  Dots,
  Dot,
  ButtonContainer,
  Button,
  LabelsWrapper,
} from "./style";
import WebPImage from "../webpImage";
import { getWebpImageUrl } from "../../utils/getImage";
import { useNavigate } from "react-router";
import useHideTutorial from "../../hooks/useHideTutorial";

const Tutorial: FC = () => {
  // TODO: Multistep content
  const [currentStep, setCurrentStep] = useState<number>(0);
  const navigate = useNavigate();
  const { mutate } = useHideTutorial();

  const steps = [
    {
      heading: "Welcome to Casino Magnat",
      text: "Game of grinding and upgrading. This quick onboarding will teach you the basics. Have fun!",
      icon: "BlackJack",
    },
    {
      heading: "Tap to Earn",
      text: "Tap the games and collect your earnings every time the timer runs out. The more frequently you tap and return to the game, the more you will be able to collect!",
      icon: "Roulette",
    },
    {
      heading: "Mine",
      text: "Upgrade casino by purchasing new levels in shop. The higher the level of your games, the more they can generate over an hour. This way, you can strategize and increase your earnings even when you’re not logged in.",
      icon: "Chip",
    },
    {
      heading: "Profit per hour",
      text: "Your casino will generate income even when you’re not logged in. However, remember to log in every hour to maximize and collect your earnings.",
      icon: "Dollar",
    },
    {
      heading: "Level up",
      text: "Upgrade the level of your games in the purchase tab. The higher the level, the more income per hour your games will generate.",
      icon: "Rocket",
    },
    {
      heading: "Friends",
      text: "Invite your friends to join the game. You earn income from each friend you refer and from each friend they refer as well. So yes, it is a pyramid scheme! Be the first to start inviting and benefit from the network effect.",
      icon: "Mascot",
    },
    {
      heading: "Token Listing",
      text: "At the end of each season, a special token will be released and distributed among the top players. Stay updated on the token release dates through our announcement channel.",
      icon: "User",
    },
    {
      heading: "Type /Help in chat",
      text: "That’s it. Your set. Forgot something? No worries! Typing /Help in chat will bring back this instructions.",
      icon: "PaperPlane",
    },
  ];

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    //   setCurrentStep(steps.length - 1);
    // navigate to casino - TODO: add pathname
    mutate();
    navigate("/auth/games");
  };
  return (
    <Card>
      <WebPImage
        style={{
          height: "122px",
          width: "122px",
          scale: "1.25",
        }}
        src={getWebpImageUrl(steps[currentStep].icon)}
        alt={`game/${steps[currentStep].icon}`}
      />
      <LabelsWrapper>
        <H2>{steps[currentStep].heading}</H2>
        <Text>{steps[currentStep].text}</Text>
      </LabelsWrapper>
      <Dots>
        {steps.map((_, index) => (
          <Dot key={index} $active={index === currentStep} />
        ))}
      </Dots>
      <ButtonContainer>
        <Button $type="primary" onClick={handleNextStep}>
          {currentStep === 0
            ? "Start onboarding"
            : currentStep !== steps.length - 1
            ? "Next Tip"
            : "Start Playing"}
        </Button>

        <Button
          $type="secondary"
          onClick={handleClose}
          disabled={currentStep >= steps.length - 1}
        >
          Skip
        </Button>
      </ButtonContainer>
    </Card>
  );
};

export default Tutorial;
