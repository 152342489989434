// Importing all created components
// import Sidebar from "../Sidebar/Sidebar";
// import Navbar from "../Navbar/Navbar";

import Footer from "../../components/footer";
import { StyledContainer } from "./style";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import { useEffect } from "react";
import { Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderBanner from "../../components/headerBanner";
import { MiniAppProvider } from "../../components/context";
import { Layout } from "..";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

// Pass the child props
export const BaseLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      localStorage.getItem("playerId") == null ||
      localStorage.getItem("refId") == null
    ) {
      // TODO: add toast?
      navigate("/");
    }
  }, []);

  const fallbackRender = ({ error, resetErrorBoundary }: FallbackProps) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
      <div
        role="alert"
        style={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Click to reload</button>
      </div>
    );
  };

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(details) => {
        console.log(details);
        navigate(`/auth/login?telegramId=${localStorage.getItem("playerId")}`);
      }}
    >
      <MiniAppProvider>
        <Layout>
          {location.pathname.includes("referrals") ? (
            <HeaderBanner />
          ) : (
            <Header />
          )}
          <StyledContainer
            position="top-left"
            autoClose={800}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            stacked
            limit={20}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Bounce}
          />
          {/*  <Sidebar /> */}
          <Outlet />
          <Footer />
          {/*<Footer /> Attach if necessary */}
        </Layout>
      </MiniAppProvider>
    </ErrorBoundary>
  );
};
