import { PageWrapper } from "../../style";
import Tutorial from "../../../components/tutorial";

const TutorialPage: React.FC = () => {
  return (
    <PageWrapper style={{ display: "flex", alignItems: "flex-end" }}>
      <Tutorial />
    </PageWrapper>
  );
};

export default TutorialPage;
