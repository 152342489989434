import useSWR from "swr";
import { IPlayer } from "../interfaces/interfaces";
import { swrSingleGetConfig } from "../configs/swrConfig";
import { useMiniAppContext } from "../components/context";

const usePlayer = () => {
  const { setState } = useMiniAppContext();
  const fetcher = async (url: string) => {
    const telegramId = localStorage.getItem("playerId");
    const response = await fetch(`${url}?TelegramId=${telegramId}`, {
      method: "GET",
    });
    // localStorage.setItem("refId", response;

    await response.json().then((data) => {
      localStorage.setItem("refId", data.id);
      setState((prevState) => ({
        ...prevState,
        userName: data.userName,
        coins: data.coins,
        perHour: data.incomePerHour,
        level: data.level,
        games: data.playerGames,
        isTutorialVisible: data.isTutorialActive,
      }));
    });

    return await response.json();
  };

  const { data, mutate, isLoading } = useSWR<IPlayer>(
    `${process.env.REACT_APP_API_URL}/Player/telegram`,
    fetcher,
    swrSingleGetConfig
  );
  return { data, isLoading, mutate };
};

export default usePlayer;
