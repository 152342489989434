import { Container, Loader } from "./style";

interface ILoadingSpinnerProps {
  size?: string;
}

const LoadingSpinner: React.FC<ILoadingSpinnerProps> = (props) => {
  const { size = "50" } = props;
  return (
    <Container>
      <Loader $size={size} viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="2"
        />
      </Loader>
    </Container>
  );
};

export default LoadingSpinner;
