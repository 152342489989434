import styled from "styled-components";

export const Card = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  gap: "2rem",
  bottom: "2rem",
});

export const LabelsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

export const H2 = styled.h2({
  fontSize: "28px",
  fontWeight: 600,
  lineHeight: "42px",
  letterSpacing: "-0.01em",
  textAlign: "center",
  color: "#FFA800",
  margin: 0,
});

export const Text = styled.p({
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "-0.01em",
  textAlign: "center",
});

export const Dots = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: ".25rem",
});

export const Dot = styled.div<{ $active: boolean }>((props) => ({
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: props.$active ? "#007bff" : "#ccc",
}));

export const ButtonContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
});

export const Button = styled.button<{ $type: "primary" | "secondary" }>(
  (props) => ({
    backgroundColor: props.$type === "primary" ? "#9747FF" : "transparent",
    color: props.$type === "primary" ? "white" : "#8C8C8C",
    padding: ".75rem 0",
    borderRadius: ".5rem",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    border: 0,
    width: "244px",
  }),
  `&:disabled {
    cursor: not-allowed;
    opacity: 0; /* Set opacity to 0 when disabled */
  }`
);
