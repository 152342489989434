import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { IPlayerGame } from "../../interfaces/interfaces";

interface MiniAppContextState {
  userName: string;
  coins: number;
  level: number;
  perHour: number;
  isTutorialVisible: boolean;
  games: IPlayerGame[];
}

interface MiniAppContextProps {
  state: MiniAppContextState;
  setState: Dispatch<SetStateAction<MiniAppContextState>>;
}

const MiniAppContext = createContext<MiniAppContextProps | undefined>(
  undefined
);

const initialState = {
  userName: "",
  coins: 0,
  level: 0,
  perHour: 0,
  isTutorialVisible: false,
  games: [],
};

export const MiniAppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<MiniAppContextState>(initialState);

  return (
    <MiniAppContext.Provider value={{ state, setState }}>
      {children}
    </MiniAppContext.Provider>
  );
};

export const useMiniAppContext = () => {
  const context = useContext(MiniAppContext);
  if (!context) {
    throw new Error("useMiniAppContext must be used within a MiniAppProvider");
  }
  return context;
};
