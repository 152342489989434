import { nanoid } from "nanoid";
import usePlayerReferrals from "../../../hooks/usePlayerReferrals";
import { PageWrapper } from "../../style";
import LoadingSpinner from "../../../components/loadingSpinner";
import { List } from "../games/style";
import CardRefferal from "../../../components/cards/cardRefferal";
import EmptyContentReferralsList from "./emptyContent";

const ReferralsPage: React.FC = () => {
  const { data, isLoading } = usePlayerReferrals();

  return (
    <PageWrapper>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {data?.items && data.items.length > 0 ? (
            <List>
              {data?.items.map((item) => (
                <CardRefferal data={item} key={nanoid()} />
              ))}
            </List>
          ) : (
            <div style={{ marginTop: "5rem", padding: "0 4rem" }}>
              <EmptyContentReferralsList />
            </div>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default ReferralsPage;
