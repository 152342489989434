//backup link `https://devapilogsstorage.blob.core.windows.net/images/${bgName}.webp`;

const cdnImageLink = (name: string) => {
  return `${process.env.REACT_APP_CDN_URL}/images/${name}.webp`;
};

//TODO : refactor into one method

export type GameType =
  | "Roulette"
  | "Slots"
  | "Poker"
  | "Jackpot"
  | "Dice"
  | "PaperPlane"
  | "Chip"
  | "User"
  | "Rocket"
  | "Dollar"
  | "Mascot"
  | "Blackjack";

export const getWebpImageUrl = (title: GameType | string): string => {
  let imageName = "";
  // Need for switch is because not all titles are consistent with cdn image names
  switch (title) {
    case "Roulette":
      imageName = "roulette";
      break;
    case "Slots":
      imageName = "slot";
      break;
    case "Poker":
      imageName = "poker";
      break;
    case "Jackpot":
      imageName = "jackpot";
      break;
    case "Dice":
      imageName = "dice";
      break;
    case "PaperPlane":
      imageName = "paper-plane";
      break;
    case "Chip":
      imageName = "chip";
      break;
    case "Rocket":
      imageName = "racket";
      break;
    case "Dollar":
      imageName = "dollar";
      break;
    case "Mascot":
      imageName = "mascot";
      break;
    case "User":
      imageName = "user3";
      break;
    default:
      imageName = "blackjack";
  }

  return cdnImageLink(imageName);
};

type BackgroundType = "background1" | "background2";

export const getBackgroundUrl = (bgName: BackgroundType): string => {
  return cdnImageLink(bgName);
};

type AvatarType = "user" | "user2" | "user3" | "user4";

export const getAvatarPicture = (name: AvatarType): string => {
  return cdnImageLink(name);
};

type PictureType = "racket" | "coin" | "mascot" | "dollar";
export const getPicture = (name: PictureType): string => {
  return cdnImageLink(name);
};
