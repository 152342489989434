import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { PageWrapper } from "../style";

const LoginPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [id, setId] = useState(localStorage.getItem("playerId"));

  if (id == null) {
    const params = new URLSearchParams(location.search);
    const tId = params.get("telegramId");

    if (typeof tId === "string") {
      localStorage.setItem("playerId", tId);
      setId(tId);
    }
  }

  useEffect(() => {
    if (typeof id === "string") {
      navigate("/auth/games");
    }
  }, [id]);

  return <PageWrapper>Player not logged</PageWrapper>;
};

export default LoginPage;
