import styled from "styled-components";

export const Container = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

type LoaderProps = {
  $size: string;
};

export const Loader = styled.svg<LoaderProps>`
  ${(props) =>
    `
  width: ${props.$size}px;
  height:  ${props.$size}px;
  `}
  animation: rotate 1s linear infinite;

  & .path {
    stroke: white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
